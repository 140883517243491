import Vue from 'vue'
import VueRouter from 'vue-router'
// import { Notification } from 'element-ui';
// import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    hidden: true,
    component: () =>
    import(
      '@/views/login.vue'
    ),
  },
  {
    path: '/index/index/user',
    name: 'user',
    hidden: true,
    component: () =>
    import(
      '@/views/saoma.vue'
    ),
  },

  // 首页
  {
    path: '/',
    redirect: '/home',
    meta: {
      icon: 'el-icon-s-home',
      title: '查看人员信息',
      requiresAuth: true,
      authoryMenu: 'home',
    },
    component: () =>
      import(
        /* webpackChunkName: "BasicLayout" */ '@/components/layouts/basicLayout'
      ),
    // 路由以children的为准
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          import(
            '@/views/Home.vue'
          ),
      },
    ],
  },
  {
    path: '/',
    meta: {
      icon: 'el-icon-s-tools',
      title: '录入人员信息',
      // authority: ['user', 'admin'],
      requiresAuth: true,
      authoryMenu: 'data',
    },
    component: () =>
      import(
        '@/components/layouts/basicLayout'
      ),
    children: [
      {
        path: '/add',
        name: 'add',
        meta: {
          title: '录入人员信息',
          icon: 'el-icon-s-tools',
        },
        component: () =>
          import(
            '@/views/add'
          ),
      },
    ],
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: {
  //     requiresAuth: true,
  //     icon: 'el-icon-s-home',
  //     title: '用户',
  //     authoryMenu: 'home',
  //   },
  //   hidden: true,
  //   component: () => import('@/views/login.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (_to, _from, next) => {
  const outerPaths = ['/', '/index/index/user']
  // let token = localStorage.getItem('token')
  let user = localStorage.getItem('user')
  // console.log(_to)
  if (user == 'administer' || outerPaths.includes(_to.path)) {
    next();
  } else {
    next("/");
  }

});

export default router

