import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false
  },
  mutations: {
    setIsCollapse: (state, val) => {
      state.isCollapse = val;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isCollapse: state => state.isCollapse,
  }
})
