<template>
  <div id="app">
    <!-- <headerVue></headerVue> -->
    <router-view/>
  </div>
</template>
<script>
// import screenfull from 'screenfull'
import moment from 'moment'
// import headerVue from '@/components/headerVue.vue';
export default {
  components: {
    // headerVue,
  },
  data() {
    return {
    }
  },
  mounted() {
    localStorage.setItem('day', moment(new Date()).format('YYYY-MM-DD'))
    // console.log('mon',moment(new Date()).format('YYYY-MM'))
    localStorage.setItem('startMon', moment(new Date()).format('YYYY-MM')+'-01')
  },
  methods: {
    
  }
}
</script>
<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // display: flex;
  // background: url(./assets/bg01.png);
  // background-size: 100% 100%;
  // min-height: 100vh;
  // width: 100%;
  // margin: 0;
}
body {
  display: block;
  margin: 0 !important;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#49c1fd, 0);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(#49c1fd, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#49c1fd, 1);
}
</style>
